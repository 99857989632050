/* eslint-disable no-console */
import { City } from "./../grpc/code-gen/city_pb.d";
import store from "@/store";
import Infrastructure from "@/infrastructure";
const infra = new Infrastructure();
import Router from "../router";
export default {
  namespaced: true,
  state: {
    cities: [],
    emptyCity: {
      id: "",
      name: "Не найден",
      utc: "3"
    }
  },
  actions: {
    async loadCitiesList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.location
          .GetCitiesList()
          .then((response) => {
            if (response) {
              s.commit(
                "addCitiesList",
                response.cityList.sort(
                  (a, b) => Number(b.isfeatured) - Number(a.isfeatured)
                )
              );
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject();
          });
      });
    },
    addCity(state: any, payload: City.AsObject): void {
      infra.location
        .AddCity(payload)
        .then((response) => {
          console.log("add city success  ", response);
          state.dispatch("loadCitiesList");
        })
        .catch((error) => {
          console.log("add city failed", error);
          store.commit("setNetworkError");
        });
    },
    updCity(state: any, payload: City.AsObject): void {
      infra.location
        .UpdCity(payload)
        .then((response) => {
          console.log("upd city success  ", response);
          state.dispatch("loadCitiesList");
        })
        .catch((error) => {
          console.log("upd city failed", error);
          store.commit("setNetworkError");
        });
    },
    delCity(state: any, payload: City.AsObject): void {
      infra.location
        .DelCity(payload)
        .then((response) => {
          console.log("del city success  ", response);
          state.dispatch("loadCitiesList");
        })
        .catch((error) => {
          console.log("del city failed", error);
          store.commit("setNetworkError");
        });
    }
  },
  mutations: {
    addCitiesList(s: any, arr): void {
      s.cities = arr;
    }
  },
  getters: {
    getCities: (s: any) => s.cities,
    getCityById: (state: any) => (id: string) => {
      const obj = state.cities.filter((city) => {
        return city.id === id;
      })[0];
      return obj || state.emptyCity;
    }
  }
};
